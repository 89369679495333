//This will be the component that displays when a contractor clicks the edit customer button on the customer detail screen.
//It should have the following fields:
// - First Name as first_name
// - Last Name as last_name
// - Address as address
// - Phone Number as phone_number
// - Email as email
// - User ID as user_id
// - Customer ID as customer_id
// - Customer Sub as customer_sub
// - Role as role

//All of these fields should allow them to be edited, then a save or cancel button should be available to save or cancel the changes.
//It should accept in a customer object.
//Each field should be a text field that allows for editing.
import React, { useState } from 'react'
import {
    TextField,
    Typography,
    Button,
    Box,
    Grid,
    useTheme,
    CircularProgress,
} from '@mui/material'
import axios from 'axios'
import PlacesAutocomplete from 'react-places-autocomplete'

import { useAuth0 } from '@auth0/auth0-react'
import Success from '../FormStatusIcons/Success'

export default function EditCustomerContractorView(props) {
    const { customer, setEditCustomerPopover, getContractorDashboard } = props
    const [firstName, setFirstName] = useState(customer?.first_name)
    const [lastName, setLastName] = useState(customer?.last_name)
    const [address, setAddress] = useState(customer?.address)
    const [phoneNumber, setPhoneNumber] = useState(customer?.phone_number)
    const [email, setEmail] = useState(customer?.email)
    const [role, setRole] = useState(customer?.role)
    const [customerSub, setCustomerSub] = useState(customer?.customer_sub)
    const [property_notes, setPropertyNotes] = useState(
        customer?.property_notes
    )
    const [user_id] = useState(customer?.user_id)
    const [formStatus, setFormStatus] = useState('idle')
    const { getAccessTokenSilently } = useAuth0()
    const theme = useTheme()

    const handleSelect = async (value) => {
        setAddress(value)
    }

    const handleSubmit = async () => {
        //make an axios call to /updateUserContractor with the updated customer object
        //then close the popover
        setFormStatus('submitting')
        const accessToken = await getAccessTokenSilently()

        axios
            .post(
                process.env.REACT_APP_BACKEND_DB + '/updateUserContractor',
                {
                    user_id: user_id,
                    first_name: firstName,
                    last_name: lastName,
                    address: address,
                    phone_number: phoneNumber,
                    email: email,
                    role: role,
                    customer_sub: customerSub,
                    property_notes: property_notes,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            )

            .then((res) => {
                setFormStatus('success')
                setTimeout(() => {
                    getContractorDashboard()
                    setEditCustomerPopover(false)
                    setFormStatus('idle')
                }, 1000)
            })
            .catch((err) => {
                setFormStatus('error')
                console.error(err)
            })
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant="h4">Editing User ID: {user_id}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    helperText="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    helperText="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    helperText="Phone Number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    helperText="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <PlacesAutocomplete
                    value={address}
                    onChange={setAddress}
                    onSelect={handleSelect}
                >
                    {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                    }) => (
                        <Box width={'100%'}>
                            <TextField
                                helperText="Address"
                                fullWidth
                                {...getInputProps({
                                    placeholder: 'Address',
                                })}
                            />
                            {suggestions.length > 0 && (
                                <Box
                                    sx={{
                                        //rounded corners
                                        borderRadius: '5px',
                                        position: 'absolute',
                                        zIndex: '2',
                                        backgroundColor:
                                            theme.palette.primary.textWhite,
                                        width: 'auto',
                                        maxHeight: '20vh',
                                        overflowY: 'scroll',
                                        padding: '0.5rem',
                                    }}
                                >
                                    {loading ? <Box>...loading</Box> : null}

                                    {suggestions.map((suggestion) => {
                                        const style = suggestion.active
                                            ? {
                                                  backgroundColor: '#41b6e6',
                                                  cursor: 'pointer',
                                              }
                                            : {
                                                  backgroundColor:
                                                      theme.palette.primary
                                                          .textWhite,
                                                  cursor: 'pointer',
                                              }
                                        return (
                                            <Box
                                                {...getSuggestionItemProps(
                                                    suggestion,
                                                    { style }
                                                )}
                                            >
                                                {suggestion.description}
                                            </Box>
                                        )
                                    })}
                                </Box>
                            )}
                        </Box>
                    )}
                </PlacesAutocomplete>
            </Grid>

            <Grid item xs={12}>
                <TextField
                    fullWidth
                    helperText="Property Notes"
                    multiline
                    rows={2}
                    value={property_notes}
                    onChange={(e) => setPropertyNotes(e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    helperText="Role"
                    value={role}
                    onChange={(e) => setRole(e.target.value.toLowerCase())}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    helperText="Customer Sub"
                    value={customerSub}
                    onChange={(e) => setCustomerSub(e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                {formStatus === 'success' && <Success title="Saved!" />}
            </Grid>

            <Grid item xs={12} container justifyContent="center">
                {
                    //if the formStatus is submitting, show a disabled button with a loading spinner, otherwise show the normal button
                    formStatus === 'submitting' ? (
                        <Button variant="contained" color="primary" disabled>
                            <CircularProgress size={24} />
                            Saving...
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            disabled={formStatus === 'submitting'}
                            sx={{
                                color: theme.palette.primary.textWhite,
                                backgroundColor: theme.palette.primary.backup,
                            }}
                            onClick={handleSubmit}
                        >
                            Save
                        </Button>
                    )
                }

                <Box mx={1} />
                <Button
                    variant="contained"
                    sx={{ backgroundColor: theme.palette.primary.main }}
                    onClick={() => setEditCustomerPopover(false)}
                >
                    Cancel
                </Button>
            </Grid>
        </Grid>
    )
}
