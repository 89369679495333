//This will be the component that holds the customer info for the contractor view on the customer detail screen.
// This should be a read only view, with a popover that allows for editing of the customer object.
//It should accept in a customer object.

//It should have the following fields:
// - First Name as first_name
// - Last Name as last_name
// - Address as address
// - Phone Number as phone_number
// - Email as email
// - User ID as user_id
// - Customer Sub as customer_sub
//- Role as role
// - Property Notes as property_notes

//It should have a button that allows for editing of the customer object.

import React, { useState } from 'react'
import { Typography, Button, Grid, Box, IconButton } from '@mui/material'
import CustomPopover from '../../modals/CustomPopover/CustomPopover'
import EditCustomerContractorView from './EditCustomerContractorView'
import theme from '../../../theme'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { ExpandLess } from '@mui/icons-material'

export default function CustomerInfoContractorView(props) {
    const { customer, getContractorDashboard } = props
    const [editCustomerPopoverVisible, setEditCustomerPopoverVisible] =
        useState(false)
    const [metadataExpanded, setMetadataExpanded] = useState(false)

    return (
        <Grid container>
            {editCustomerPopoverVisible && (
                <CustomPopover
                    setPopoverVisible={setEditCustomerPopoverVisible}
                >
                    <EditCustomerContractorView
                        customer={customer}
                        editCustomerPopoverVisible={editCustomerPopoverVisible}
                        setEditCustomerPopover={setEditCustomerPopoverVisible}
                        getContractorDashboard={getContractorDashboard}
                    />
                </CustomPopover>
            )}
            <Grid item xs={12}>
                <Typography
                    variant="h6"
                    sx={{
                        color: theme.palette.primary.textWhite,
                        fontSize: '2rem',
                        fontWeight: 'title',
                    }}
                >
                    {customer?.first_name + ' ' + customer?.last_name}
                </Typography>
            </Grid>

            <Grid item xs={12} container>
                <Grid item xs={12}>
                    <Box display="flex" flexDirection="row">
                        <Typography
                            variant="h6"
                            sx={{
                                color: theme.palette.primary.textWhite,
                                fontSize: '1.25rem',
                                fontWeight: 'regular',
                            }}
                        >
                            {customer?.address || 'N/A'}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        variant="h6"
                        sx={{
                            color: theme.palette.primary.textWhite,
                            fontSize: '1.25rem',
                            fontWeight: 'regular',
                        }}
                    >
                        {customer?.phone_number || 'N/A'}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        variant="h6"
                        sx={{
                            color: theme.palette.primary.textWhite,
                            fontSize: '1.25rem',
                            fontWeight: 'regular',
                        }}
                    >
                        {customer?.email || 'N/A'}
                    </Typography>
                </Grid>
                <Grid item xs={12} display={'flex'} flexDirection={'column'}>
                    <Box display="flex" flexDirection="row">
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    color: theme.palette.primary.textWhite,
                                    fontSize: '1rem',
                                    fontWeight: 'light',
                                }}
                            >
                                Metadata
                            </Typography>
                        </Box>
                        <IconButton
                            onClick={() =>
                                setMetadataExpanded(!metadataExpanded)
                            }
                            sx={{
                                color: theme.palette.primary.textWhite,
                            }}
                        >
                            {metadataExpanded ? (
                                <ExpandLess
                                    sx={{
                                        fontSize: '1rem',
                                        color: theme.palette.primary.textWhite,
                                    }}
                                />
                            ) : (
                                <ExpandMoreIcon
                                    sx={{
                                        fontSize: '1rem',
                                        color: theme.palette.primary.textWhite,
                                    }}
                                />
                            )}
                        </IconButton>
                    </Box>
                    {metadataExpanded && (
                        <>
                            <Grid item xs={12}>
                                <Box display="flex" flexDirection="row">
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            color: theme.palette.primary
                                                .textWhite,
                                            fontSize: '1rem',
                                            fontWeight: 'regular',
                                        }}
                                        paddingRight={1}
                                    >
                                        Sub:
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            color: theme.palette.primary
                                                .textWhite,
                                            fontSize: '1rem',
                                            fontWeight: 'light',
                                        }}
                                    >
                                        {customer?.customer_sub || 'N/A'}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex" flexDirection="row">
                                    <Typography
                                        variant="h6"
                                        paddingRight={1}
                                        sx={{
                                            color: theme.palette.primary
                                                .textWhite,
                                            fontSize: '1rem',
                                            fontWeight: 'regular',
                                        }}
                                    >
                                        Role:
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            color: theme.palette.primary
                                                .textWhite,
                                            fontSize: '1rem',
                                            fontWeight: 'light',
                                        }}
                                    >
                                        {customer?.role || 'N/A'}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box display="flex" flexDirection="row">
                                    <Typography
                                        variant="h6"
                                        paddingRight={1}
                                        sx={{
                                            color: theme.palette.primary
                                                .textWhite,
                                            fontSize: '1rem',
                                            fontWeight: 'regular',
                                        }}
                                    >
                                        ID #:
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            color: theme.palette.primary
                                                .textWhite,
                                            fontSize: '1rem',
                                            fontWeight: 'light',
                                        }}
                                    >
                                        {customer?.user_id}
                                    </Typography>
                                </Box>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>

            <Grid
                item
                xs={12}
                //center items
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setEditCustomerPopoverVisible(true)}
                >
                    Edit Customer
                </Button>
            </Grid>
        </Grid>
    )
}
