//This will be a simple search bar that will be used to search for customers
//It will be used in the contractor dashboard
//it will take in a prop called setCustomerSearch which will be a function that will be used to set the customer search
//it will also take in a prop called customerSearch which will be the current customer search
//It should use MUI components

import React from 'react'

import { Box, Grid, TextField } from '@mui/material'
import { FormControlLabel } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Checkbox from '@mui/material/Checkbox'

export default function CustomerSearch(props) {
    const theme = useTheme()

    return (
        <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            backgroundColor={theme.palette.primary.textWhite}
            borderRadius={1}
        >
            <Grid
                container
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'center'}
                alignItems={'center'}
                padding={2}
            >
                <Grid item xs={6}>
                    {
                        //make a box to put these two checkboxes next to each other
                    }
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={props.searchActiveCustomers}
                                    style={{
                                        color: theme.palette.primary.backup,
                                    }}
                                    onChange={(event) =>
                                        props.setSearchActiveCustomers(
                                            event.target.checked
                                        )
                                    }
                                    name="searchActiveCustomers"
                                    color="primary"
                                />
                            }
                            label="Active Customers"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={props.searchPendingJobs}
                                    style={{
                                        color: theme.palette.primary.backup,
                                    }}
                                    onChange={(event) =>
                                        props.setSearchPendingJobs(
                                            event.target.checked
                                        )
                                    }
                                    name="searchPendingJobs"
                                    color="primary"
                                />
                            }
                            label="Pending Jobs"
                        />
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={props.searchPastDueInvoices}
                                style={{
                                    color: theme.palette.primary.backup,
                                }}
                                onChange={(event) =>
                                    props.setSearchPastDueInvoices(
                                        event.target.checked
                                    )
                                }
                                name="searchPastDueInvoices"
                                color="primary"
                            />
                        }
                        label="Past Due Invoices"
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        id="outlined-basic"
                        label="Search Customers"
                        variant="outlined"
                        fullWidth
                        onChange={(event) =>
                            props.setCustomerSearch(event.target.value)
                        }
                        value={props.customerSearch}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}
