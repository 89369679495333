import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { CircularProgress } from '@mui/material'
import Grid from '@mui/material/Grid'
import moment from 'moment'
import { useTheme } from '@mui/material/styles'
import ViewChargePopover from '../../../modals/ViewChargePopover/ViewChargePopover'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

const CustomerCharges = ({ charges, isLoading }) => {
    const theme = useTheme()
    const [viewChargePopoverVisible, setViewChargePopoverVisible] =
        React.useState(false)
    const [viewCharge, setViewCharge] = React.useState()
    const [paidFilter, setPaidFilter] = React.useState('all')

    const handleViewCharge = (charge) => {
        setViewCharge(charge)
        setViewChargePopoverVisible(true)
    }

    if (!charges || isLoading) {
        return (
            <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Box
                    display={'flex'}
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    padding={2}
                >
                    <Typography
                        variant="h4"
                        color={theme.palette.primary.textWhite}
                    >
                        Charges
                    </Typography>
                </Box>
                <CircularProgress />
            </Box>
        )
    }

    const handleFilterChange = (event, newFilter) => {
        setPaidFilter(newFilter)
    }

    const filteredCharges = charges.filter((charge) => {
        if (paidFilter === 'all') {
            return true
        } else if (paidFilter === 'invoiced') {
            return charge.charge_status === 'invoiced'
        } else if (paidFilter === 'pending') {
            return charge.charge_status === 'pending'
        }
        return false
    })

    return (
        <Box>
            {viewChargePopoverVisible && (
                <ViewChargePopover
                    setPopoverVisible={setViewChargePopoverVisible}
                    charge={viewCharge}
                />
            )}
            <Box
                display={'flex'}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                padding={2}
            >
                <Typography
                    variant="h4"
                    color={theme.palette.primary.textWhite}
                >
                    Charges
                </Typography>
            </Box>
            <Box
                display={'flex'}
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                padding={2}
            >
                <Typography
                    variant="h6"
                    color={theme.palette.primary.textWhite}
                    style={{ marginRight: '10px' }}
                >
                    Filter by:
                </Typography>

                <ToggleButtonGroup
                    value={paidFilter}
                    exclusive
                    onChange={handleFilterChange}
                    style={{ backgroundColor: 'black' }}
                >
                    <ToggleButton
                        value="all"
                        style={{
                            backgroundColor:
                                paidFilter === 'all' ? 'gray' : 'black',
                            color: paidFilter === 'all' ? 'white' : 'gray',
                        }}
                    >
                        All
                    </ToggleButton>
                    <ToggleButton
                        value="invoiced"
                        style={{
                            backgroundColor:
                                paidFilter === 'invoiced' ? 'gray' : 'black',
                            color: paidFilter === 'invoiced' ? 'white' : 'gray',
                        }}
                    >
                        Invoiced
                    </ToggleButton>
                    <ToggleButton
                        value="pending"
                        style={{
                            backgroundColor:
                                paidFilter === 'pending' ? 'gray' : 'black',
                            color: paidFilter === 'pending' ? 'white' : 'gray',
                        }}
                    >
                        Pending
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
            {!filteredCharges.length ? (
                <Typography
                    variant="body1"
                    textAlign={'center'}
                    color={theme.palette.primary.textWhite}
                >
                    No Results Found
                </Typography>
            ) : null}
            {filteredCharges
                ?.sort((a, b) => {
                    return new Date(b.date_serviced) - new Date(a.date_serviced)
                })
                .map((charge, index) => (
                    <Card
                        key={index}
                        style={{
                            margin: '10px',
                            padding: '10px',
                            border: '1px solid black',
                            //hover effect
                        }}
                    >
                        <CardContent
                            sx={{
                                '&:hover': {
                                    backgroundColor: 'lightgray',
                                    cursor: 'pointer',
                                },
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                padding: '10px',
                                position: 'relative',
                            }}
                            onClick={() => handleViewCharge(charge)}
                        >
                            <Grid container>
                                <Grid item xs={12} sm={9}>
                                    <Typography
                                        variant="h6"
                                        style={{
                                            fontWeight: 'bold',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        Charge #{charge.charge_id}
                                    </Typography>
                                    <Typography>
                                        Service Performed:{' '}
                                        {charge.service_performed}
                                    </Typography>
                                    <Typography>
                                        Date Serviced:{' '}
                                        {moment
                                            .utc(charge.date_serviced)
                                            .format('MM/DD/YYYY')}
                                    </Typography>
                                    <Typography>
                                        Amount: {charge.amount}
                                    </Typography>
                                </Grid>
                                <Grid container item xs={12} sm={3}>
                                    <Grid item xs={6} md={12}>
                                        <Typography
                                            variant="h6"
                                            style={{
                                                fontWeight: 'bold',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            Charge Status:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={12}>
                                        <Typography>
                                            {charge.charge_status ===
                                            'invoiced' ? (
                                                <span
                                                    style={{
                                                        color: theme.palette
                                                            .primary
                                                            .successGreen,
                                                        fontWeight: 'bold',
                                                        fontSize: '1.2em',
                                                    }}
                                                >
                                                    [INVOICED]
                                                </span>
                                            ) : charge.charge_status ===
                                              'pending' ? (
                                                <span
                                                    style={{
                                                        color: theme.palette
                                                            .primary
                                                            .cautionYellow,
                                                        fontWeight: 'bold',
                                                        fontSize: '1.2em',
                                                    }}
                                                >
                                                    [PENDING]
                                                </span>
                                            ) : charge.charge_status ===
                                              'cancelled' ? (
                                                <span
                                                    style={{
                                                        color: theme.palette
                                                            .primary.errorRed,
                                                        fontWeight: 'bold',
                                                        fontSize: '1.2em',
                                                    }}
                                                >
                                                    [CANCELLED]
                                                </span>
                                            ) : null}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                ))}
        </Box>
    )
}

export default CustomerCharges
