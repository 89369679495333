import React, { useState } from 'react'
import axios from 'axios'
import { Close } from '@mui/icons-material'
import { Box } from '@mui/system'
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableContainer,
    TableHead,
    Button,
    TextField,
    Typography,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    CircularProgress,
} from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import Success from '../../Components/FormStatusIcons/Success'
import { useTheme } from '@mui/material/styles'

export default function ConfirmRoute({
    setPopoverVisible,
    proposedRoute,
    contractorID,
    setBackToRoutes,
}) {
    const theme = useTheme()
    const { getAccessTokenSilently } = useAuth0()

    const [routeName, setRouteName] = useState(
        `Route ${new Date().toLocaleDateString()}`
    )
    const [routeStatus, setRouteStatus] = useState('Scheduled')
    const [routeDescription, setRouteDescription] = useState('')

    const [submitting, setSubmitting] = useState('idle')

    const handleSubmit = () => {
        setSubmitting('submitting')
        const getJWT = async () => {
            const accessToken = await getAccessTokenSilently()

            const customerIDs = proposedRoute.map((customer) => {
                return customer.user_id
            })

            axios
                .post(
                    `${process.env.REACT_APP_BACKEND_DB}/addRoute`,
                    {
                        contractor_id: contractorID,
                        route_name: routeName,
                        route_customers: customerIDs,
                        next_stop: customerIDs[0],
                        route_description: routeDescription,
                        route_status: routeStatus,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
                .then((response) => {
                    setSubmitting('success')
                    setTimeout(() => {
                        setSubmitting('idle')
                        setPopoverVisible(false)
                        setBackToRoutes()
                    }, 1000)
                })
        }
        getJWT()
    }

    return (
        <Box
            position={'fixed'}
            top={'0'}
            left={'0'}
            width={'100%'}
            height={'100%'}
            backgroundColor={'rgba(0, 0, 0, 0.5)'}
            sx={{
                backdropFilter: 'blur(5px)',
            }}
            color={theme.palette.primary.textBlack}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            zIndex={'2'}
        >
            <Box
                width={'100%'}
                height={'100%'}
                position={'absolute'}
                top={'0'}
                left={'0'}
                onClick={() => setPopoverVisible(false)}
            ></Box>
            <Box
                zIndex={'1'}
                position={'relative'}
                width={'auto'}
                height={'auto'}
                backgroundColor={'white'}
                borderRadius={'10px'}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                padding={'5vh'}
                maxHeight={'90vh'}
                //scroll
                overflow={'scroll'}
            >
                <Box
                    display={'flex'}
                    justifyContent={'flex-end'}
                    alignItems={'flex-start'}
                    width={'100%'}
                    marginBottom={'10px'}
                >
                    <Close
                        position={'static'}
                        color={'black'}
                        fontWeight={'bold'}
                        cursor={'pointer'}
                        onClick={() => setPopoverVisible(false)}
                    />
                </Box>

                <Box textAlign={'center'}>
                    <Typography variant={'h4'}>Confirm Route</Typography>

                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        width={'100%'}
                    >
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            width={'100%'}
                            padding={1}
                        >
                            <TextField
                                fullWidth
                                label={'Route Name'}
                                variant={'outlined'}
                                value={routeName}
                                onChange={(e) => setRouteName(e.target.value)}
                            />
                        </Box>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            width={'100%'}
                            padding={1}
                        >
                            <TextField
                                fullWidth
                                label={'Route Description'}
                                //multiline
                                // rows={2}
                                variant={'outlined'}
                                value={routeDescription}
                                onChange={(e) =>
                                    setRouteDescription(e.target.value)
                                }
                            />
                        </Box>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            width={'100%'}
                            padding={1}
                        >
                            <FormControl fullWidth>
                                <InputLabel>Status</InputLabel>
                                <Select
                                    id="route-status"
                                    value={routeStatus}
                                    label="Route Status"
                                    onChange={(e) =>
                                        setRouteStatus(e.target.value)
                                    }
                                >
                                    <MenuItem value={'Completed'}>
                                        Completed
                                    </MenuItem>
                                    <MenuItem value={'Cancelled'}>
                                        Cancelled
                                    </MenuItem>
                                    <MenuItem value={'In Progress'}>
                                        In Progress
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                    <Typography
                        variant={'h6'}
                        textAlign={'center'}
                        paddingTop={2}
                    >
                        Route
                    </Typography>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Address</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {proposedRoute?.map((customer) => {
                                    return (
                                        <TableRow key={customer.user_id}>
                                            <TableCell>
                                                {customer.first_name}
                                            </TableCell>
                                            <TableCell>
                                                {customer.address}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {submitting === 'success' && (
                        <Box padding={'1rem'}>
                            <Success title={'Route Saved'} />
                        </Box>
                    )}
                    {submitting === 'idle' && (
                        <Box padding={'1vh'}>
                            <Button variant="contained" onClick={handleSubmit}>
                                Save Route
                            </Button>
                        </Box>
                    )}
                    {submitting === 'submitting' && (
                        <Box padding={'1vh'}>
                            <Button variant={'contained'} disabled>
                                <CircularProgress size={24} />
                                Submitting...
                            </Button>
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    )
}
